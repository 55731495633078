import { User } from '@portals/api';

export type Device = {
	id: number;
	model: string;
	device_os: string;
	app_version: string;
	push_token: string;
	user: User | number;
	user_agent ?: string
};

export type RegisterDevicePayload = Omit<Device, 'id'>;
