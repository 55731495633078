import { Injectable } from '@angular/core';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { AuthService, ConfirmationPayload, ForgotPasswordPayload, LoginPayload, RegisterPayload, ResetPasswordPayload, User } from '@portals/api';
import { Ability, AbilityBuilder } from '@casl/ability';

import { finalize, tap } from 'rxjs/operators';
import { environment } from '@portals/environments';
import { CookieService } from 'ngx-cookie';
import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
// import * as fflate from 'fflate';

export interface StateModel {
	isLoading: boolean;
}

// TODO: Rename this to auth state
@Persistence()
@StateRepository()
@State<StateModel>({
	name: 'auth',
})
@Injectable()
export class AuthState extends NgxsDataRepository<StateModel> {
	constructor(private authService: AuthService, private ability: Ability, private cookieService: CookieService) {
		super();
	}

	@Computed()
	get token() {
		return localStorage.getItem('auth-key');
	}

	@Computed()
	get isAuthenticated() {
		return !!localStorage.getItem('auth-key');
	}

	@Computed()
	get blocked() {
		const decodedToken = jwt_decode(<string>localStorage.getItem('auth-key')) as any;
		return !!decodedToken.user.blocked;
	}

	@Computed()
	get data() {
		const decodedToken = jwt_decode(<string>localStorage.getItem('auth-key')) as any;
		return decodedToken.user;
	}

	authenticate(payload?: LoginPayload) {
		this.patchState({ isLoading: true });

		return this.authService
			.login(
				payload
					? payload
					: {
							identifier: this.data.username,
							password: this.data.username,
					  }
			)
			.pipe(
				tap(async (data: any) => {
					const decodedToken = (await jwt_decode(data.jwt)) as any;
					await localStorage.setItem('auth-key', data.jwt);
					this.updateAbility(decodedToken.user);
					this.patchState({
						isLoading: false,
					});
				})
			);
	}

	register(payload: RegisterPayload) {
		this.patchState({ isLoading: true });

		return this.authService.registerUser(payload).pipe(
			tap(async (data: any) => {
				const decodedToken = (await jwt_decode(data.jwt)) as any;
				await localStorage.setItem('auth-key', data.jwt);
				this.updateAbility(decodedToken.user);
				this.patchState({
					isLoading: false,
				});
			}),
			finalize(() => {
				this.authService.getUser(<string>localStorage.getItem('auth-key')).subscribe(async (data: any) => {
					const decodedToken = (await jwt_decode(data.jwt)) as any;
					await localStorage.removeItem('auth-key');
					await localStorage.setItem('auth-key', data.jwt);
					this.updateAbility(decodedToken.user);
					this.patchState({
						isLoading: false,
					});
				});
			})
		);
	}

	forgotPassword(payload: ForgotPasswordPayload) {
		this.patchState({ isLoading: true });

		return this.authService.forgotPassword(payload, { responseType: 'json' }).pipe(
			tap((data) => {
				return data;
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	confirmUser(payload: ConfirmationPayload) {
		this.patchState({ isLoading: true });
		return this.authService.confirmUser(payload).pipe(
			tap((data) => {
				return data;
			}),
			finalize(() => {
				this.patchState({ isLoading: false });
			})
		);
	}

	resetPassword(payload: ResetPasswordPayload) {
		this.patchState({ isLoading: true });

		return this.authService.resetPassword(payload).pipe(
			tap(async (data: any) => {
				await this.patchState({
					isLoading: false,
				});
			}),
			finalize(async () => {
				await localStorage.removeItem('auth-key');
				this.patchState({
					isLoading: false,
				});
			})
		);
	}

	updateUser(user: any) {
		return this.authService.getUser(user.jwt).pipe(
			tap(async (data: any) => {
				const decodedToken = (await jwt_decode(data.jwt)) as any;
				await localStorage.removeItem('auth-key');
				await localStorage.setItem('auth-key', data.jwt);
				this.updateAbility(decodedToken.user);
				this.patchState({
					isLoading: false,
				});
			})
		);
	}

	// updateAbility(user: User) {
	// 	const { can, rules } = new AbilityBuilder(Ability);
	//
	// 	if (user) {
	// 		const userType = user.role.type;
	// 		const isMobileJustice = environment.app === 'Mobile Justice';
	//
	// 		switch (userType) {
	// 			case 'authenticated':
	// 				can('manage', 'feed');
	// 				break;
	// 			case 'super_admin':
	// 				if (isMobileJustice) {
	// 					can('manage', 'dashboard');
	// 					can('manage', 'incidents');
	// 					can('manage', 'projects');
	// 					can('manage', 'portals');
	// 					can('manage', 'users');
	// 				} else {
	// 					can('manage', 'all');
	// 				}
	// 				break;
	// 			case 'aclu_admin':
	// 				can('manage', 'dashboard');
	// 				can('manage', 'incidents');
	// 				can('manage', 'users');
	// 				break;
	// 			case 'intake_viewer':
	// 				can('manage', 'incidents');
	// 				can('manage', 'collections');
	// 				break;
	// 			case 'agent':
	// 				can('manage', 'reports');
	// 				can('manage', 'mappings');
	// 				can('manage', 'events');
	// 				can('manage', 'labs');
	// 				can('manage', 'items');
	// 				can('manage', 'locations');
	// 				break;
	// 			case ('project_manager' || 'beta_user'):
	// 				if (isMobileJustice) {
	// 					can('manage', 'dashboard');
	// 					can('manage', 'incidents');
	// 					can('manage', 'collections');
	// 					can('manage', 'notifications');
	// 					can('manage', 'rights');
	// 					can('manage', 'users');
	// 				} else {
	// 					can('manage', 'channels');
	// 					can('manage', 'dashboard');
	// 					can('manage', 'leads');
	// 					can('manage', 'events');
	// 					can('manage', 'notifications');
	// 					can('manage', 'rights');
	// 					can('manage', 'jotts');
	// 					can('manage', 'prompts');
	// 					can('manage', 'posts');
	// 					can('manage', 'videos');
	// 					can('manage', 'incidents');
	// 					can('manage', 'collections');
	// 					can('manage', 'reports');
	// 					can('manage', 'items');
	// 					can('manage', 'intakes');
	// 					can('manage', 'responses');
	// 					can('manage', 'mappings');
	// 					can('manage', 'locations');
	// 				}
	// 				break;
	// 			case 'lab_admin':
	// 				can('manage', 'channels');
	// 				can('manage', 'dashboard');
	// 				can('manage', 'leads');
	// 				can('manage', 'events');
	// 				can('manage', 'notifications');
	// 				can('manage', 'rights');
	// 				can('manage', 'jotts');
	// 				can('manage', 'prompts');
	// 				can('manage', 'posts');
	// 				can('manage', 'videos');
	// 				can('manage', 'incidents');
	// 				can('manage', 'collections');
	// 				can('manage', 'reports');
	// 				can('manage', 'items');
	// 				can('manage', 'intakes');
	// 				can('manage', 'responses');
	// 				can('manage', 'mappings');
	// 				can('manage', 'locations');
	// 				break;
	// 			default:
	// 				can('read', 'all');
	// 				break;
	// 		}
	// 	} else {
	// 		can('read', 'all');
	// 	}
	//
	// 	this.ability.update(rules);
	// }

	updateAbility(user: User) {
		const { can, rules } = new AbilityBuilder(Ability);

		user?.role.type === 'authenticated'
			? can('manage', 'feed')
			: user?.role.type === 'super_admin' && environment.app == 'Mobile Justice'
			? (can('manage', 'dashboard'), can('manage', 'analytics'), can('manage', 'incidents'), can('manage', 'projects'), can('manage', 'portals'), can('manage', 'users'))
			: user?.role.type === 'aclu_admin' && environment.app == 'Mobile Justice'
			? (can('manage', 'dashboard'), can('manage', 'analytics'), can('manage', 'incidents'), can('manage', 'users'))
			: user?.role.type === 'super_admin'
			? can('manage', 'all')
			: user?.role.type === 'intake_viewer'
			? (can('manage', 'incidents'), can('manage', 'collections'))
			: user?.role.type === 'agent'
			? (can('manage', 'reports'), can('manage', 'mappings'), can('manage', 'events'), can('manage', 'labs'), can('manage', 'items'), can('manage', 'locations'))
			: user?.role.type === 'affiliate_admin'
			? (can('manage', 'dashboard'), can('manage', 'analytics'), can('manage', 'incidents'), can('manage', 'collections'), can('manage', 'notifications'), can('manage', 'rights'), can('manage', 'users'))
			: user?.role.type === ('project_manager' || 'beta_user') || 'lab_admin'
			? (can('manage', 'channels'),
			  can('manage', 'analytics'),
			  can('manage', 'dashboard'),
			  can('manage', 'leads'),
			  can('manage', 'events'),
			  can('manage', 'notifications'),
			  can('manage', 'rights'),
			  can('manage', 'jotts'),
			  can('manage', 'jott responses'),
			  can('manage', 'prompts'),
			  can('manage', 'jott prompts'),
			  can('manage', 'posts'),
			  can('manage', 'videos'),
			  can('manage', 'incidents'),
			  can('manage', 'collections'),
			  can('manage', 'reports'),
			  can('manage', 'items'),
			  can('manage', 'intakes'),
			  can('manage', 'responses'),
			  can('manage', 'mappings'),
			  can('manage', 'locations'))
			: can('read', 'all');

		this.ability.update(rules);
	}

	async logout() {
		await this.cookieService.removeAll();
		await localStorage.clear();
		await sessionStorage.clear();
		await this.reset();
		await location.reload();
	}
}
