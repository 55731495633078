import { Collection } from '@portals/api';

import { Channel } from '../channel';
import { Project } from '../project';
import { User } from '../user';
import { Comment } from '../comment';
import { Tag } from '../tag';
import { Location } from '../../core';
import { Notification } from '../notification';

export type ContactInfo = {
	name: string;
	phone_number: string;
	email: string;
};

export enum CollectibleType {
	report = 'report',
	police_report = 'police_report',
	stream = 'stream',
	text = 'text',
}
export interface MapData {
	id: number;
	uuid: string;
	latitude: number;
	notification_id: number;
	channel_id: number;
	longitude: number;
	type: string;
	external_url?: string;
	created_at: Date;
	transcription_text: string | null;
	textResponse: string| null;
	channel_name: string;
	channel_url: string | null;
	title: string;
	stacked?: boolean;
	stackId?: string;
	video: string;
}
export type TrustedContactPayload = {
	email?: string;
	domain?: string;
	app?: string;
	logo?: string;
	primaryHex?: string;
	secondaryHex?: string;
	phone_number?: number;
	video?: string;
	trusted_contacts?: {
		contact1?: any;
		contact2?: any;
		contact3?: any;
	};
};

export type Collectible = {
	id: number;
	status?: string;
	archived?: boolean;
	textResponse?: string;
	user: User;
	channel: Channel;
	project: Project;
	notification?: Notification;
	type: CollectibleType;
	data: any;
	video?: string;
	contact_info: ContactInfo;
	collections: Collection[];
	comments: Comment[];
	flagged: boolean;
	seen: boolean;
	tags: Tag[];
	uuid: string;
	location: Location;
	stacked?: boolean;
	stackId?: string;
	transcription_text?: string;
	transcript_gist_set?: boolean;
	has_transcript_in_bucket?: boolean;
	// created_at: string;
	// updated_at: string;
	// published_at: string;
	publishedAt: Date;
	createdAt: Date;
	updatedAt: Date;
	audioOnly?: boolean;
};
