import {Injectable, NgZone} from '@angular/core';
import {AuthState, ChannelsState, RolesState, UsersState} from '@portals/store';
import {finalize, from, mergeMap, tap} from 'rxjs';
import {Router, ActivatedRoute} from '@angular/router';
import {CookieService} from 'ngx-cookie';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Observable, of, map} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	constructor(private router: Router, private roles: RolesState, private auth: AuthState, private user: UsersState, private route: ActivatedRoute, private cookies: CookieService, private deviceService: DeviceDetectorService, private zone: NgZone) {
	}

	registerUser(formValue: any, channel: any): Observable<any> {
		if (!this.cookies.get('username')) {
			this.cookies.put('username', formValue.username);
			return this.user.createMobileUser(formValue).pipe(
				mergeMap((data) => {
					return this.auth
						.authenticate({
							identifier: `${formValue.username}@strapi.com`,
							password: formValue.password,
						})
						.pipe(
							mergeMap(() => {
								return from(this.user.registerDeviceToken()).pipe(
									mergeMap(data => {
										return this.user
											.registerDevice(data)
											.pipe(
												mergeMap((data) => {
													this.cookies.put('deviceID', String(data.id));
													if (channel) {
														return this.followChannel(channel);
													} else return of('')
												})
											)
									}))
							})
						)
				})
			);
		} else {
			this.user.updateUserTimestamp(this.auth.data.id, {last_accessed: Date.now()}).subscribe()
			if (channel) {
				return this.followChannel(channel);
			} else {
				return of(null);
			}
		}
	}

	followChannel(channel: any, device?: any): Observable<any> {
		const deviceId = this.cookies.get('deviceID');
		const follows = <string>this.cookies.get('follows');
		return this.user.followChannel(channel.id, Number(deviceId)).pipe(
			map(() => {
				const followList = this.cookies.get('follows') || '[]';
				const follows = JSON.stringify([...JSON.parse(followList), channel.id.toString()]);
				this.cookies.put('follows', follows);
			}),
			tap(() => {
				if ((!follows && channel.id != 1) || (follows && !follows.includes('1'))) {
					this.user
						.followChannel(1, Number(deviceId))
						.subscribe();
				}
			})
		);
	}

	removeQueryParam(param: string, follows?: any, channel?: string) {
		const queryParams = {...this.route.snapshot.queryParams};
		Object.keys(queryParams).length === 0
			? this.router.navigate(follows ? ['/'] : ['/', 'onboarding'])
			: (delete queryParams[param],
				channel
					? this.router.navigate(['/channels', channel]).then(() => {
						const currentUrl = this.router.url;
						this.router.routeReuseStrategy.shouldReuseRoute = () => false;
						this.router.onSameUrlNavigation = 'reload';
						this.router.navigate([currentUrl]);
					})
					: this.router.navigate([], {queryParams: queryParams}).then(() => {
						const currentUrl = this.router.url;
						this.router.routeReuseStrategy.shouldReuseRoute = () => false;
						this.router.onSameUrlNavigation = 'reload';
						this.router.navigate([currentUrl]);
					}));
	}
}
