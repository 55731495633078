import { Channel } from '../channel';
import { Notification } from '../notification';

export type Link = {
	domain: LINK_DOMAINS;
	endpoint: string;
	type: LINK_TYPES;
	type_id: number;
};

export enum LINK_DOMAINS {
	JOTTO_PROD_WEB_APP = 'https://jotto.me/',
	JOTTO_STAGING_WEB_APP = 'https://staging-app.jotto.me/',
	LET_DEM_KNOW = 'https://letdemknow.com/',
}

export enum LINK_TYPES {
	CHANNEL = 'CHANNEL',
	PROMPT = 'PROMPT',
}

export type LINK_RESPONSE = {
	ads: any;
	type: LINK_TYPES;
	entity: Notification | Channel;
};
