import { Channel, Project, StrapiMedia } from '@portals/api';
import {RESPONSE_TYPES} from "@portals/utils";

interface IChartRawData {
	date: string;
	row_count: string;
}
interface ParsedChartData {
	count: number[];
	date: string[];
}
interface IChartData {
	all: number[];
	video: number[];
	audio_only: number[];
}
export interface IParseChart {
	allResponses: ParsedChartData;
	allVideos?: ParsedChartData;
	allAudios?: ParsedChartData;
	allTexts?: ParsedChartData;
	allReports?: ParsedChartData;
	allPoliceReport?: ParsedChartData;
}
// export interface IResponseChart {
// 	all: IChartRawData[];
// 	video?: IChartRawData[];
// 	text?: IChartRawData[];
// 	report?: IChartRawData[];
// 	policeReport?: IChartRawData[];
// 	audio_only?: IChartRawData[];
// 	total_count?: number;
// 	total_video_count?: number;
// 	total_text_count?: number;
// 	total_report_count?: number;
// 	total_policeReport_count?: number;
// 	total_audio_only_count?: number;
// }

export interface IResponseChart {
	allResponses: IChartRawData[];
	allProjectResponses?: IChartRawData[];
	allVideos?: IChartRawData[];
	projectVideos?: IChartRawData[];
	allAudios?: IChartRawData[];
	projectAudios?: IChartRawData[];
	allTexts?: IChartRawData[];
	projectTexts?: IChartRawData[];
	allReports?: IChartRawData[];
	projectReports?: IChartRawData[];
	allPoliceReports?: IChartRawData[];
	projectPoliceReports?: IChartRawData[];
	total_count?: number;
	total_project_count?: number;
	total_videos_count?: number;
	total_project_videos_count?: number;
	total_audios_count?: number;
	total_project_audios_count?: number;
	total_texts_count?: number;
	total_project_texts_count?: number;
	total_reports_count?: number;
	total_project_reports_count?: number;
	total_policeReports_count?: number;
	total_project_policeReports_count?: number;
}

export type Reaction = {
	icon: string;
	count?: number;
	percentage?: number;
	is_selected?: boolean;
};

export enum NotificationStateEnum {
	draft = 'DRAFT',
	live = 'LIVE',
	pending = 'PENDING',
	expired = 'EXPIRED',
	template = 'TEMPLATE',
}

export type Notification = {
	sponsor_image: StrapiMedia;
	orderIndex: number;
	maxVideoLength?: number;
	shouldRedirect ?: boolean;
	triggers?: Trigger[];
	id: number;
	expanded?: boolean;
	project?: Project;
	title: string;
	message: string;
	external_url?: string;
	external_video_url?: string;
	external_url_name?: string;
	image?: StrapiMedia;
	video?: StrapiMedia;
	media?: {
		image?: StrapiMedia;
		video?: StrapiMedia;
	};
	sent?: Date;
	state: NotificationStateEnum;
	reactions?: Reaction[];
	channel?: Channel;
	sent_to?: number;
	allow_video_feedback?: boolean;
	view_count?: number;
	link_count?: number;
	alt_stv_url?: string;
	stv_url?: string;
	notify?: boolean;
	featured?: boolean;
	expires_at?: number;
	schedule_for?: number;
	custom_link_text?: string;
	response_type: RESPONSE_TYPES;
	type: string;
	type_id: number;
	duration?: {
		start: string;
		end: string;
	};
	cta: string;
	publishedAt: Date;
	createdAt: Date;
	updatedAt: Date;
	stvTrigger?: any;
	linkTrigger?: any;
	videoTrigger?: any;
};

export type CreateNotificationPayload = {
	channel: Channel;
	title: string;
	message: string;
	external_url: string;
	external_url_name?: string;
};

export type NotificationData = {
	image?: string;
	video?: string;
	value?: Notification;
};

export type Trigger = {
	type: string;
	type_id: number;
	delay: number;
	message: Notification;
	id: number;
};
export type NotificationRecents = {
	title: string;
	view_count: number;
	id: number;
	created_at: Date;
	sent_to: number;
	message: string;
	external_url: string;
	external_url_name: string;
	external_video_url: string;
	preview_url: any;
	url: string;
	channel_name: string;
	channel_id: number;
	channel_img_name: string;
	channel_preview_url: any;
	channel_img_url: string;
};

export type UpdateNotification = Omit<Notification, 'reactions'>;
