import { Environment, JottoWebEnvironments } from '@portals/environments';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment & JottoWebEnvironments = {
	production: false,
	app: 'Jotto Web',
	// baseUrl: 'https://staging-api.jotto.me/v2',
	baseUrl: 'http://localhost:1337/v2',
	transcriptionApiKey: 'maTsPkGPVDaJq1bkdP5WH3DdBM7Tz8Qc9Lpda6jS',
	primaryHex: '#491176',
	secondaryHex: '#fbd354',
	logo: 'https://g3-jotto-uploads.s3.amazonaws.com/jotto_accent_cef194081a.png',
	public: true,
	tutorial: true,
	multiProject: true,
	terms: 'https://jotto.me/terms',
	policy: 'https://jotto.me/privacy',
	support: 'developer@quadrant2.tech',
	s3ThumbnailBucket: 'https://g3-jotto-video-thumbnails.s3.amazonaws.com/',
	mapBoxToken: 'sk.eyJ1IjoiYWxleG9ub3pvciIsImEiOiJja2YydTV6ZGMwMXd6MnpxcW5yb3pkYm84In0.mIW08fd6Mb1VZ9nkYN5h9Q',
	firebase: {
		apiKey: 'AIzaSyAMT2P0L8ccFwmNKuZHtU2Yo5ErJYnXTdQ',
		authDomain: 'jotto-f8a17.firebaseapp.com',
		databaseURL: 'https://jotto-f8a17.firebaseio.com',
		projectId: 'jotto-f8a17',
		storageBucket: 'jotto-f8a17.appspot.com',
		messagingSenderId: '924869180612',
		appId: '1:924869180612:web:f64d3a43cfe7ae8eb01d91',
		measurementId: 'G-7FL5WS4207',
	},
	version: '1.0.0',
	specialRoutes: true,
	defaultNotification: 14,
	defaultChannel: '0f86127a-dd3f-40ab-a2d2-ee922a32415b',
	portalUrl: 'https://staging-portal.jotto.me',
	defaultDynamicLink: 'https://jotto.link/1Xcp',
};
